<template>
  <v-col
    cols="12"
    class="d-flex flex-column justify-center align-center emoji test-end--emoji"
  >
    <v-img
      v-if="!isRiddle"
      :src="image"
      alt="smiley"
      max-height="300"
      max-width="300"
      contain
      eager
    ></v-img>
    <template v-if="reachedMaxRetries && !saveSuccess && !saving">
      <p class="text-h5 text-center white--text unselectable norddruck mt-4">
        {{ $t('test-end.save-error') }}
      </p>
      <p class="text-h5 text-center white--text unselectable norddruck mb-4">
        {{ $t('test-end.save-error2') }}
      </p>
      <v-col class="pa-0 d-flex flex-grow-0 justify-center">
        <v-btn
          class="start-btn ma-1 mt-1"
          rounded
          color="#ead148"
          @click="save"
          :loading="loading"
          max-width="300"
          :disabled="loading || finalSuccess"
        >
          <p
            class="ma-0 text-h6 text-sm-h5 text-md-h5 text-lg-h5 text-xl-h4 norddruck pt-1"
          >
            {{ finalSuccess ? $t('test-end.final-success') : $t('save') }}
          </p>
        </v-btn>
      </v-col>
      <SavingInfoBar />
    </template>
    <template v-else>
      <v-progress-circular class="mt-4" size="50" indeterminate color="#ead148">
      </v-progress-circular>
    </template>
  </v-col>
</template>

<script lang="ts">
import router from '@/router';
import FantasyModule from '@/store/modules/FantasyModule';
import LettersModule from '@/store/modules/LettersModule';
import RiddleModule from '@/store/modules/RiddleModule';
import SchoolModule from '@/store/modules/SchoolModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import SyllableModule from '@/store/modules/SyllableModule';
import UserModule from '@/store/modules/UserModule';
import WordsModule from '@/store/modules/WordsModule';
import { getSchoolPrefix } from '@/utils/common';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { Client, Form, Test } from '../../api/types/api';
import SavingInfoBar from './SavingInfoBar.vue';

@Component({
  components: {
    SavingInfoBar,
  },
})
export default class TestEndEmoji extends Vue {
  userModule: UserModule = getModule(UserModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);

  @Prop({ default: true })
  saving?: boolean;

  @Prop({ default: false })
  reachedMaxRetries?: boolean;

  @Prop({ default: false })
  saveSuccess?: boolean;

  @Prop()
  module?:
    | LettersModule
    | FantasyModule
    | SyllableModule
    | WordsModule
    | RiddleModule;

  path = this.$route.name!;
  loading = false;
  finalSuccess = false;

  get image(): string {
    return require('@/assets/images/lion_small.png');
  }

  get isRiddle(): boolean {
    return this.path === Test.riddle;
  }

  get isFindWords(): boolean {
    return this.path === Test.words;
  }

  get isTherapyClient(): boolean {
    return this.schoolModule.clientType === Client.therapy;
  }

  @Watch('saveSuccess', { immediate: true })
  onWatchError(): void {
    if (this.saveSuccess) {
      console.log('[TestEndEmoji] SUCCESS!!!');
      this.goToOverview();
    }
  }

  getEndSound(path: string): HTMLAudioElement {
    let sound: HTMLAudioElement;
    if (path === Test.letters) {
      sound = new Audio('/audio/toll.mp3');
    } else if (path === Test.fantasy) {
      sound = new Audio('/audio/grossartig.mp3');
    } else if (path === Test.syllables) {
      sound = new Audio('/audio/ausgezeichnet.mp3');
    } else {
      sound = new Audio('/audio/toll.mp3');
    }
    return sound;
  }

  mounted(): void {
    // INFO play sound on find words
    if (this.isFindWords) {
      const sound = this.getEndSound(this.path);
      sound.play();
    }
  }

  goToOverview(): void {
    const form = this.$route.params.form;
    const prefix = getSchoolPrefix();
    let timeInMs = 0;
    // INFO set time out to wait for sound and then move to the next
    if (this.path === Test.fantasy || this.path === Test.syllables) {
      timeInMs = 3000;
    } else if (this.isRiddle) {
      // INFO going to congratulation on riddle container - show overview with completed tests
      this.$router.push({ path: `/${prefix}/tests/${form}`, replace: true });
      return;
    } else {
      timeInMs = 6000;
    }
    console.log('[TestEndEmoji] going to overview');
    setTimeout(async () => {
      router.push({ path: `/${prefix}/tests/${form}`, replace: true });
    }, timeInMs);
  }

  async afterRiddle() {
    const prefix = getSchoolPrefix();
    const superAudio = new Audio('/audio/tutorials/u1/HELP3833.mp3');
    const congratulations = new Audio('/audio/gratuliere_alles.mp3');

    // if (this.isTherapyClient) {
    //   console.log('after riddle i am coming here TEST END EMOJI');
    //   try {
    //     await this.schoolModule.decreaseValidTests(this.schoolModule.slug);
    //     await this.schoolModule.getBasicSchoolInfo(this.schoolModule.slug);
    //   } catch (error) {
    //     console.log('[TEST END] could not fetch school info', error);
    //   }
    // }
    const form = this.$route.params.form;
    congratulations.addEventListener(
      'ended',
      () => {
        setTimeout(async () => {
          const currentModule = this.module as RiddleModule;
          currentModule?.haveShownCongrats(form);
          router.replace({
            name: 'congratulations',
            params: { schoolName: prefix },
          });
          setTimeout(() => superAudio.play(), 1000);
        }, 2300);
      },
      { once: true },
    );
    congratulations.play();
  }

  async save(): Promise<void> {
    if (this.module && this.reachedMaxRetries) {
      try {
        this.loading = true;
        const res = await this.module.saveStatus(
          this.userModule.currentUser._id,
        );

        if (this.isRiddle) {
          const userId = this.userModule.currentUser._id;
          const updateData =
            this.module?.chosenForm === Form.a
              ? { doneA: true }
              : { doneB: true };
          await this.userModule.updateUser({ id: userId, data: updateData });
        }

        if (res?.status === 200) {
          this.finalSuccess = true;
          this.module.finishedAndSavedTest();
          this.snackbarModule.showMessage({
            message: this.$t('snackbar.test-end.save-success').toString(),
            isSuccess: true,
          });

          if (this.isRiddle) {
            await this.afterRiddle();
          }
          this.goToOverview();
        }
      } catch (error: any) {
        this.module.resetDone();
        console.error(
          '[SAVING TEST END] error saving status',
          error?.code,
          error?.message,
          error?.name,
          error?.response?.data?.error,
          error?.response?.data?.message,
          error?.response?.data?.statusCode,
        );
        this.snackbarModule.showMessage({
          message: this.$t('snackbar.test-end.save-error').toString(),
          isSuccess: false,
        });
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.emoji {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10;
  background: linear-gradient(
    209deg,
    rgba(49, 37, 112, 1) 0%,
    rgba(49, 126, 164, 1) 35%,
    rgba(47, 190, 199, 1) 100%
  );
}

.start-btn {
  width: 55%;
  height: 53px !important;

  p {
    color: $dark-blue !important;
  }
}

// @media screen and (min-width: 600px) {
//   .start-btn {
//     width: 50%;
//     height: 48px !important;
//   }
// }

// @media screen and (min-width: 960px) {
//   .start-btn {
//     width: 50%;
//     height: 48px !important;
//   }
// }
// @media screen and (min-width: 1264px) {
//   .start-btn {
//     width: 40%;
//     height: 60px !important;
//   }
// }

// @media screen and (min-width: 1904px) {
//   .start-btn {
//     width: 35%;
//     height: 60px !important;
//   }
// }
</style>
