<template>
  <v-snackbar
    :value="snackbar"
    :timeout="timeout"
    :color="color"
    absolute
    rounded="pill"
    right
    top
    class="pa-0"
  >
    <v-row class="d-flex justify-center align-center">
      <v-img :src="image" contain max-height="50" max-width="50" eager />
      <p class="ma-0 ml-2 pa-0 body-1">{{ message }}</p>
    </v-row>
  </v-snackbar>
</template>

<script lang="ts">
import SnackbarModule from '@/store/modules/SnackbarModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';

@Component
export default class SavingInfoBar extends Vue {
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);

  get image(): string {
    return require('@/assets/images/lion_small.png');
  }

  get snackbar(): boolean {
    return this.snackbarModule.showSnackbar;
  }

  get message(): string {
    return this.snackbarModule.message;
  }

  get timeout(): number {
    return this.snackbarModule.timeout;
  }

  get color(): string {
    return this.snackbarModule.color;
  }
}
</script>

<style lang="scss" scoped></style>
