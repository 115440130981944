<template>
  <v-btn class="scroll-up" color="primary" @click="scrollUp" fab>
    <v-icon class="pa-3" large>mdi-arrow-up</v-icon>
  </v-btn>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class ScollUpButton extends Vue {
  scrollUp(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
</script>

<style scoped lang="scss">
.scroll-up {
  position: fixed;
  right: 2%;
  bottom: 5%;
  z-index: 20;
}
</style>
