<template>
  <audio ref="audioElement" :src="require('@/assets/audio/click.mp3')" />
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Ref } from 'vue-property-decorator';
import { clickEventBus } from '../../utils/common';
@Component({})
export default class ClickAudio extends Vue {
  @Ref() audioElement!: HTMLAudioElement;

  mounted(): void {
    this.audioElement.volume = 0.2;
    clickEventBus.$on('play', async () => {
      if (!this.audioElement.paused && this.audioElement.currentTime > 0) {
        this.audioElement.currentTime = 0;
      }
      await this.audioElement.play();
    });
  }
}
</script>
