<template>
  <v-col
    cols="auto"
    class="center-component d-flex flex-column justify-center align-center"
  >
    <v-progress-circular
      v-if="!hasImage"
      class="center-component"
      indeterminate
      size="64"
      color="primary"
    ></v-progress-circular>
    <p
      v-if="hasImage"
      class="text-h4 text-sm-h4 text-md-h4 text-lg-h4 text-xl-h4 white--text norddruck"
    >
      {{ $t('tests.show-you') }}
    </p>
    <v-col cols="5" sm="5" md="6" lg="7">
      <v-img v-show="hasImage" contain :src="leo" eager @load="setHasImage" />
    </v-col>

    <v-col cols="12" class="d-flex justify-center">
      <v-btn
        v-if="hasImage"
        rounded
        color="#ead148"
        class="start-btn ma-1 mt-1"
        @click="start"
        :loading="loadingAudios"
        :disabled="disabled || loadingAudios"
      >
        <p
          class="ma-0 text-h5 text-sm-h5 text-md-h5 text-lg-h5 text-xl-h5 norddruck pt-1"
        >
          {{ $t('next') }}
        </p>
      </v-btn>
    </v-col>
  </v-col>
</template>

<script lang="ts">
import UserModule from '@/store/modules/UserModule';
import WordsModule from '@/store/modules/WordsModule';
import {
  Pathname,
  tutorialFantasyAudios,
  tutorialLettersAudios,
  tutorialRiddleAudios,
  tutorialSyllableAudios,
  tutorialWordsAudios,
} from '@/utils/helpers';
import { precacheFiles } from '@/utils/common';
import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';

@Component({})
export default class StartTestButton extends Vue {
  userModule: UserModule = getModule(UserModule, this.$store);
  wordsModule: WordsModule = getModule(WordsModule, this.$store);
  hasImage = false;
  disabled = true;

  loadingAudios = false;
  files: string[] = [];
  loadedAudios = 0;

  get isLetters(): boolean {
    return this.$route.path.includes(Pathname.letters);
  }

  get isFantasy(): boolean {
    return this.$route.path.includes(Pathname.fantasy);
  }

  get isSyllable(): boolean {
    return this.$route.path.includes(Pathname.syllables);
  }

  get isWords(): boolean {
    return this.$route.path.includes(Pathname.words);
  }

  get isRiddle(): boolean {
    return this.$route.path.includes(Pathname.riddle);
  }

  get format(): string {
    return this.wordsModule.imageFormat;
  }

  get fantasyImages(): string[] {
    return [
      `/images/fantasy/Max.${this.format}`,
      `/images/fantasy/Lisa.${this.format}`,
      `/images/fantasy/Max-and-Lisa.${this.format}`,
      `/images/fantasy/Max_ALT.${this.format}`,
      `/images/fantasy/Lisa_ALT.${this.format}`,
      `/images/fantasy/Max-and-Lisa_ALT.${this.format}`,
    ];
  }

  defineTutorialAudioFiles(): void {
    this.files = this.isLetters
      ? tutorialLettersAudios
      : this.isFantasy
      ? tutorialFantasyAudios
      : this.isSyllable
      ? tutorialSyllableAudios
      : this.isWords
      ? tutorialWordsAudios
      : tutorialRiddleAudios;
  }

  defineTestData(): void {
    // check if to load r and b for letters!
    if (this.isLetters) {
      this.files = [
        ...this.files,
        `/audio/letters/r.mp3`,
        `/audio/letters/b.mp3`,
      ];
    } else if (this.isFantasy) {
      const fantasyAudios = [
        `/audio/fantasy/tutorial/mo.mp3`,
        `/audio/fantasy/tutorial/schoni.mp3`,
        `/audio/fantasy/tutorial/si.mp3`,
        `/audio/fantasy/tutorial/rula.mp3`,
      ];
      this.files = [...this.files, ...this.fantasyImages, ...fantasyAudios];
    } else if (this.isWords) {
      const testImages = [
        `/images/find-words/Mund.${this.format}`,
        `/images/find-words/Eis.${this.format}`,
      ];
      this.files = [...this.files, ...testImages];
    }
  }

  async mounted(): Promise<void> {
    // INFO wait for previous sound to finish
    setTimeout(() => {
      this.disabled = false;
    }, 1880);
    // INFO preloading all relevant test data
    this.defineTutorialAudioFiles();
    this.defineTestData();
    try {
      this.loadingAudios = true;
      await precacheFiles(this.files);
    } catch (error) {
      console.log('error', error);
    } finally {
      this.loadingAudios = false;
    }
  }

  get leo(): string {
    return require('@/assets/images/lion_small.png');
  }

  start(): void {
    this.userModule.stopActiveAudio();
    this.$emit('start');
  }

  setHasImage(): void {
    this.hasImage = true;
  }

  get lettersAudios() {
    return {
      intro: '/audio/tutorials/u1/intro.mp3',
      listen: '/audio/tutorials/u1/HELP3839.mp3',
      clickCorrectLetter: '/audio/tutorials/u1/HELP274.mp3',
      clickOrangeLetter: '/audio/tutorials/u1/HELP264.mp3',
      wellDone: '/audio/tutorials/u1/HELP290.mp3',
      super: '/audio/tutorials/u1/HELP3833.mp3',
      wrong: '/audio/tutorials/u1/HELP3834.mp3',
      rightLetterHint: '/audio/tutorials/u1/HELP3835.mp3',
      readyGo: '/audio/tutorials/u1/HELP3836.mp3',
    };
  }

  get fantasyAudios() {
    return {
      bothRead: '/audio/tutorials/u2/HELP256.mp3',
      maxReads: '/audio/tutorials/u2/HELP291.mp3',
      lisaReads: '/audio/tutorials/u2/HELP3833.mp3',
      clickCorrectImage: '/audio/tutorials/u2/HELP269.mp3',
      clickOrangeAnswer: '/audio/tutorials/u2/HELP264.mp3',
      whoReadCorrectly: '/audio/tutorials/u2/HELP283.mp3',
      wellDone: '/audio/tutorials/u2/HELP291.mp3',
      super: '/audio/tutorials/u2/HELP3833.mp3',
      wrongMaxCorrect: '/audio/tutorials/u2/HELP258.mp3',
      wrongLisaCorrect: '/audio/tutorials/u2/HELP257.mp3',
      readyGo: '/audio/tutorials/u2/HELP3836.mp3',
    };
  }

  get syllableAudios() {
    return {
      intro: '/audio/tutorials/u3/HELP232.mp3',
      first: '/audio/tutorials/u3/HELP231.mp3',
      second: '/audio/tutorials/u3/HELP233.mp3',
      last: '/audio/tutorials/u3/HELP280.mp3',
      hereWrong: '/audio/tutorials/u3/HELP277.mp3',
      nowPractice: '/audio/tutorials/u3/HELP237.mp3',
      nowClickInBetween: '/audio/tutorials/u3/HELP238.mp3',
      wrong: '/audio/tutorials/u3/HELP239.mp3',
      showYouAgain: '/audio/tutorials/u3/HELP240.mp3',
      super: '/audio/tutorials/u3/HELP3833.mp3',
      wellDone: '/audio/tutorials/u3/HELP289.mp3',
      ready: '/audio/tutorials/u3/HELP3841.mp3',
      readyGo: '/audio/tutorials/u3/HELP3836.mp3',
    };
  }

  get wordAudios() {
    return {
      lookImage: '/audio/tutorials/u4/HELP246.mp3',
      clickCorrectWord: '/audio/tutorials/u4/HELP247.mp3',
      clickOrangeWord: '/audio/tutorials/u4/HELP264.mp3',
      rightWordHint: '/audio/tutorials/u4/HELP275.mp3',
      veryGood: '/audio/tutorials/u4/HELP288.mp3',
      super: '/audio/tutorials/u4/HELP3833.mp3',
      wrong: '/audio/tutorials/u4/HELP3834.mp3',
      readyGo: '/audio/tutorials/u4/HELP3836.mp3',
    };
  }

  get riddleAudios() {
    return {
      readQuestion: '/audio/tutorials/u5/HELP248.mp3',
      clickCorrectWords: '/audio/tutorials/u5/HELP286.mp3',
      showCorrectWords: '/audio/tutorials/u5/HELP276.mp3',
      clickArrow: '/audio/tutorials/u5/HELP3818.mp3',
      congrats: '/audio/tutorials/u5/HELP3830.mp3',
      super: '/audio/tutorials/u5/HELP3833.mp3',
      wrong: '/audio/tutorials/u5/HELP239.mp3',
      readyGo: '/audio/tutorials/u5/HELP3836.mp3',
    };
  }
}
</script>

<style scoped lang="scss">
.start-btn {
  width: 70%;
  height: 53px !important;

  p {
    color: $dark-blue !important;
  }
}

@media screen and (min-width: 600px) {
  .start-btn {
    width: 50%;
    height: 48px !important;
  }
}

@media screen and (min-width: 960px) {
  .start-btn {
    width: 50%;
    height: 48px !important;
  }
}
@media screen and (min-width: 1264px) {
  .start-btn {
    width: 60%;
    height: 60px !important;
  }
}
</style>
