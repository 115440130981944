<template>
  <v-col
    cols="12"
    sm="8"
    md="8"
    lg="6"
    xl="7"
    class="message-col d-flex flex-column justify-center align-center center-component"
  >
    <p
      v-if="hasImage"
      class="text-h4 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4 text-center white--text unselectable norddruck motivation-text"
      v-html="text"
    ></p>
    <v-img
      v-show="hasImage"
      class="emoji"
      :src="image"
      alt="leo"
      max-height="200"
      max-width="200"
      contain
      align="start"
      eager
      @load="setHasImage"
    ></v-img>
    <v-btn
      v-if="hasImage && !isSyllableTutorial"
      rounded
      color="#ead148"
      class="start-btn ma-1 mt-1"
      @click="start"
      :loading="loadingAudios"
      :disabled="disabled || loadingAudios"
    >
      <p
        class="ma-0 text-h5 text-sm-h5 text-md-h5 text-lg-h5 text-xl-h4 norddruck pt-1"
      >
        {{ $t('start') }}
      </p>
    </v-btn>
  </v-col>
</template>

<script lang="ts">
import LettersModule from '@/store/modules/LettersModule';
import RiddleModule from '@/store/modules/RiddleModule';
import SyllableModule from '@/store/modules/SyllableModule';
import UserModule from '@/store/modules/UserModule';
import WordsModule from '@/store/modules/WordsModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import {
  lettersAudios,
  fantasyAAudios,
  fantasyBAudios,
  findWordsImages_a,
  findWordsImages_b,
  Pathname,
} from '@/utils/helpers';
import { precacheFiles } from '@/utils/common';
import FantasyModule from '@/store/modules/FantasyModule';

@Component
export default class TutorialEndEmoji extends Vue {
  @Prop() audio?: string;
  @Prop({ default: false }) text?: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) isTutorial?: boolean;
  @Prop() module?: SyllableModule | RiddleModule | LettersModule | WordsModule;

  userModule: UserModule = getModule(UserModule, this.$store);
  fantasyModule: FantasyModule = getModule(FantasyModule, this.$store);
  wordsModule: WordsModule = getModule(WordsModule, this.$store);
  hasImage = false;

  files: string[] = [];
  loadingAudios = false;

  get image(): string {
    return require('@/assets/images/lion_small.png');
  }

  get isSyllableTutorial() : boolean {
    return this.$route.path.includes(Pathname.syllables) && !!this.isTutorial;
  }


  setHasImage(): void {
    this.hasImage = true;
  }

  defineTestData(): void {
    const isLetters = this.$route.path.includes(Pathname.letters);
    const isFantasy = this.$route.path.includes(Pathname.fantasy);
    const isWords = this.$route.path.includes(Pathname.words);

    if (isLetters) {
      const testAudios = lettersAudios.map(
        (letter) => `/audio/letters/${letter}.mp3`,
      );
      this.files = [...testAudios];
    } else if (isFantasy) {
      const form = this.fantasyModule.currentForm;
      const formAudios = form === 'a' ? fantasyAAudios : fantasyBAudios;
      const testAudios = formAudios.map(
        (audio) => `/audio/fantasy/form-${form}/${audio}.mp3`,
      );
      this.files = [...testAudios];
    } else if (isWords) {
      const form = this.wordsModule.currentForm;
      const format = this.wordsModule.imageFormat;
      const formImages = form === 'a' ? findWordsImages_a : findWordsImages_b;
      const testImages = formImages.map(
        (image) => `/images/find-words/${image}.${format}`,
      );
      this.files = [...testImages];
    }
  }

  async mounted(): Promise<void> {
    // INFO preload relevant test data here!
    this.defineTestData();
    try {
      this.loadingAudios = true;
      await precacheFiles(this.files);
    } catch (error) {
      console.log('error', error);
    } finally {
      this.loadingAudios = false;
    }
  }

  start(): void {
    this.userModule.stopActiveAudio();
    this.$emit('start');
  }
}
</script>

<style scoped lang="scss">
.tutorial-row {
  width: 100%;
  line-height: 1.5 !important;
  flex: 1;
}

.message-col {
  width: 100%;
  max-height: 80%;
}

.emoji {
  flex: 8;
}

.start-btn {
  width: 55%;
  height: 53px !important;

  p {
    color: $dark-blue !important;
  }
}

.motivation-text {
  line-height: 1.5;
}

@media screen and (min-width: 600px) {
  .start-btn {
    width: 50%;
    height: 48px !important;
  }
}

@media screen and (min-width: 960px) {
  .start-btn {
    width: 50%;
    height: 48px !important;
  }
}
@media screen and (min-width: 1264px) {
  .start-btn {
    width: 40%;
    height: 60px !important;
  }
}

@media screen and (min-width: 1904px) {
  .start-btn {
    width: 35%;
    height: 60px !important;
  }
}
</style>
