<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="400"
    max-height="300"
    class="justify-end"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        top
        v-on="on"
        text
        class="logoutButton white--text text-none"
      >
        <p
          v-if="!isMobile"
          class="pa-0 ma-0 text-h6 text-sm-h6 text-md-h6 text-lg-h6 text-xl-h6 norddruck"
        >
          {{ buttonText }}
        </p>
        <v-icon large color="white" class="ma-1">
          {{ icon }}
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="font-weight-bold justify-center unselectable">
        <p
          class="text-h5 text-sm-h4 text-md-h4 text-lg-h4 text-xl-h4 norddruck"
        >
          {{ $t('attention') }}
        </p>
      </v-card-title>
      <v-card-text class="text-body-1 black--text text-center unselectable"
        ><p
          class="norddruck text-h5 text-sm-h6 text-md-h6 text-lg-h6 text-xl-h6"
        >
          {{ description }}
        </p>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary darken-2 unselectable"
          text
          large
          @click="action"
          class="norddruck dialog-size"
          :loading="loadingLogout"
        >
          {{ $t('yes') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary darken-2 unselectable"
          text
          large
          @click="dialog = false"
          class="norddruck dialog-size"
        >
          {{ $t('no') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import router from '@/router';
import FantasyModule from '@/store/modules/FantasyModule';
import LettersModule from '@/store/modules/LettersModule';
import RiddleModule from '@/store/modules/RiddleModule';
import SyllableModule from '@/store/modules/SyllableModule';
import WordsModule from '@/store/modules/WordsModule';
import UserModule from '@/store/modules/UserModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { Test } from '../../api/types/api';
import store from '../../store';
import localforage from 'localforage';
import SchoolModule from '@/store/modules/SchoolModule';
import { getSchoolPrefix } from '@/utils/common';

@Component
export default class BaseHeaderButton extends Vue {
  @Prop() icon!: string;
  @Prop() description!: string;
  @Prop() isLogout!: boolean;

  userModule: UserModule = getModule(UserModule, this.$store);
  lettersModule: LettersModule = getModule(LettersModule, this.$store);
  wordsModule: WordsModule = getModule(WordsModule, this.$store);
  riddleModule: RiddleModule = getModule(RiddleModule, this.$store);
  fantasyModule: FantasyModule = getModule(FantasyModule, this.$store);
  syllableModule: SyllableModule = getModule(SyllableModule, this.$store);
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);

  dialog = false;
  loadingLogout = false;

  get buttonText(): string {
    return this.isLogout ? 'Abmelden' : 'Abbrechen';
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown;
  }

  @Watch('dialog')
  onChanged(curr: any): void {
    if (curr) {
      this.userModule.stopActiveAudio();
    }
  }

  reset() {
    this.fantasyModule.resetFormat();
    this.wordsModule.resetFormat();
    this.riddleModule.resetCongrats();
    this.schoolModule.reset();
    this.lettersModule.reset();
    this.fantasyModule.reset();
    this.syllableModule.reset();
    this.wordsModule.reset();
    this.riddleModule.reset();
  }

  async logout(): Promise<void> {
    //clear localStorage when leaving
    store.commit('logout');
    this.userModule.logout();
    this.reset();
    localStorage.clear();
    await localforage.dropInstance({ name: 'lesikus', storeName: 'letters' });
    await localforage.dropInstance({ name: 'lesikus', storeName: 'fantasy' });
    await localforage.dropInstance({ name: 'lesikus', storeName: 'syllable' });
    await localforage.dropInstance({ name: 'lesikus', storeName: 'words' });
    await localforage.dropInstance({ name: 'lesikus', storeName: 'riddle' });
    await localforage.clear();
  }

  async action(): Promise<void> {
    const prefix = getSchoolPrefix();
    if (this.isLogout) {
      this.loadingLogout = true;
      await this.logout();
      this.loadingLogout = false;
      router.push({ name: 'login', params: { schoolName: prefix } });
    } else {
      this.resetSyllable();
      router.push({ path: `/${prefix}/tests/${this.$route.params.form}` });
    }
  }

  resetSyllable(): void {
    if (this.$route.name === Test.syllables) {
      if (!this.syllableModule.hasDoneTutorial)
        this.syllableModule.resetAnswers();
    }
  }
}
</script>

<style scoped lang="scss">
.base-header {
  z-index: 1;
  i {
    cursor: pointer;
  }
}

.dialog-row {
  width: 97%;
}

.login-color {
  color: $yellow;
}

.title-color {
  color: white;
}

.dialog-size {
  font-size: 1.2rem !important;
}

.logoutButton {
  position: absolute;
  right: 2%;
  top: 3%;
  z-index: 150;
}
</style>
