<template>
  <v-footer padless class="base-footer py-0 mt-2" color="transparent">
    <v-col
      class="d-flex justify-center align-end text-center pa-0 pb-1"
      cols="12"
    >
      <p class="caption ma-0 unselectable">
        <!-- <a href="" @click.prevent="goToImprint">Kontakt Lesikus</a> | -->
        <a href="" @click.prevent="goToImprint">{{ $t('imprint.title') }}</a>
        <!-- <a href="">Datenschutzerklärung</a>  -->
      </p>
    </v-col>
  </v-footer>
</template>

<script lang="ts">
import { getSchoolPrefix } from '@/utils/common';
import Vue from 'vue';
import Component from 'vue-class-component';
import router from '../../router';

@Component
export default class BaseFooter extends Vue {
  goToImprint(): void {
    router.push({
      name: 'impressum',
      params: { schoolName: getSchoolPrefix() },
    });
  }
}
</script>

<style scoped lang="scss">
.base-footer {
  // position: absolute;
  // bottom: 0;
  // background-position: bottom;
  width: 100%;

  * {
    color: white;
  }
  .col {
    height: 100%;
  }

  a {
    font-family: $font-family, sans-serif !important;
    text-decoration: none;
  }

  @media (hover: hover) and (pointer: fine) {
    a:hover {
      color: black;
    }
  }
}
</style>
