<template>
  <v-col
    :class="{ disableMultipleClick: isDisabled }"
    cols="3"
    sm="2"
    md="2"
    lg="2"
    xl="2"
    class="repeat-btn"
    align="center"
  >
    <v-btn :disabled="isDisabled" fab dark color="primary">
      <v-icon>mdi-replay</v-icon>
    </v-btn>
    <p
      :class="{ disableMultipleClick: isDisabled }"
      class="button white--text my-2 unselectable"
    >
      Beispiele wiederholen
    </p>
  </v-col>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class RepeatButton extends Vue {
  @Prop() isDisabled!: boolean;
}
</script>

<style lang="scss" scoped>
.repeat-btn {
  position: absolute !important;
  bottom: 10%;
  right: 0%;
  z-index: 15;

  p {
    letter-spacing: 1px;
  }
}

@media screen and (max-width: 600px) {
  .repeat-btn {
    bottom: 10%;
    right: 5%;
  }
}

@media screen and (max-width: 960px) {
  .repeat-btn {
    bottom: 10%;
    right: 2%;
  }
}
</style>
