<template>
  <v-col
    cols="12"
    class="general-header d-flex flex-column justify-center align-center"
    :class="isTest ? 'general-header__testHeight' : ''"
  >
    <BaseHeaderBackButton />
    <BaseHeaderActionButton
      v-if="isStart && isNotImprint"
      :icon="icon"
      :description="'Willst du dich wirklich ausloggen?'"
      :isLogout="true"
    />

    <p
      v-if="isLogin"
      class="text-h3 text-sm-h3 text-md-h2 text-lg-h2 text-xl-h1 mt-16 mx-4 mb-6 unselectable login-color text-center myHeadline"
    >
      {{ title }}
    </p>
    <v-row v-if="isLogin" class="d-flex align-center">
      <p
        class="text-h5 text-sm-h4 text-md-h4 text-lg-h3 text-xl-h3 mx-4 mt-0 mb-0 unselectable white--text text-center myHeadline"
      >
        {{ subtitle }}
      </p>
      <v-btn icon large :disabled="isDisabled">
        <v-icon large color="white" @click="playTestSound"
          >mdi-volume-high
        </v-icon>
      </v-btn>
    </v-row>

    <p
      v-else
      class="general-header__title text-h3 text-sm-h3 text-md-h3 text-lg-h2 text-xl-h2 unselectable white--text text-center myHeadline align-self-center"
    >
      {{ title }}
    </p>
  </v-col>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import FantasyModule from '../../store/modules/FantasyModule';
import LettersModule from '../../store/modules/LettersModule';
import RiddleModule from '../../store/modules/RiddleModule';
import SyllableModule from '../../store/modules/SyllableModule';
import WordsModule from '../../store/modules/WordsModule';
import BaseHeaderActionButton from './BaseHeaderActionButton.vue';
import BaseHeaderBackButton from './BaseHeaderBackButton.vue';

@Component({
  components: { BaseHeaderBackButton, BaseHeaderActionButton },
})
export default class GeneralHeader extends Vue {
  @Prop() title!: string;
  @Prop() subtitle?: string;

  lettersModule: LettersModule = getModule(LettersModule, this.$store);
  wordsModule: WordsModule = getModule(WordsModule, this.$store);
  riddleModule: RiddleModule = getModule(RiddleModule, this.$store);
  fantasyModule: FantasyModule = getModule(FantasyModule, this.$store);
  syllableModule: SyllableModule = getModule(SyllableModule, this.$store);

  dialog = false;
  isDisabled = false;

  get notHome(): boolean {
    return this.$route.path != '/';
  }

  get isStart(): boolean {
    return (
      this.$route.name === 'start' ||
      this.$route.name === 'tests' ||
      this.$route.name === 'form' ||
      this.$route.name === 'parentInfo' ||
      this.$route.name === 'congratulations'
    );
  }

  get isNotImprint(): boolean {
    return this.$route.name !== 'impressum';
  }

  get notCongratulations(): boolean {
    return this.$route.name != 'congratulations';
  }

  get isLogin(): boolean {
    return this.$route.name === 'login' || this.$route.name === 'info';
  }

  get isTest(): boolean {
    return this.$route.path.includes('test');
  }

  get icon(): string {
    const name = this.$route.name;

    if (name === 'start' || name === 'tests' || name === 'form') {
      return 'mdi-exit-to-app';
    } else {
      return 'mdi-close';
    }
  }

  playTestSound(): void {
    this.isDisabled = true;
    const sound = new Audio('/audio/tutorials/u1/HELP3833.mp3');
    sound.addEventListener(
      'ended',
      () => {
        this.isDisabled = false;
      },
      { once: true },
    );
    sound.play();
  }
}
</script>

<style scoped lang="scss">
.general-header {
  &__title {
    margin-top: 4%;
    z-index: 5;
  }

  &__testHeight {
    height: 20vh;
  }
}

.login-color {
  color: $yellow;
}

.title-color {
  color: white;
}
</style>
