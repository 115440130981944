<template>
  <v-btn
    color="#ead148"
    text
    id="riddleAnswerBtn"
    :class="{
      disabledArrow: disableButtons || preventArrow,
      hide: hideButton,
    }"
  >
    <v-icon id="arrow" class="pa-3" x-large>mdi-arrow-right-thick</v-icon>
  </v-btn>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class ArrowButton extends Vue {
  @Prop({ default: false }) disableButtons?: boolean;
  @Prop({ default: false }) preventArrow?: boolean;
  @Prop({ default: false }) hideButton?: boolean;
}
</script>

<style scoped lang="scss">
.disabledArrow {
  cursor: default;
  pointer-events: none;
}

.hide {
  display: none;
}

#riddleAnswerBtn {
  height: 45px;
}

@media only screen and (max-width: 800px) {
  #riddleAnswerBtn {
    height: 45px;
  }

  #arrow {
    font-size: 4rem !important;
  }
}
</style>
